import React from "react";



const Faq = () => {
    return (
        <div className="faq-body">
            <head>
                <title>FAQ</title>
            </head>

            <div>
                <h1 className="h1">FAQ</h1>
                <details open>
                    <summary>
                        What is MFA?
                    </summary>
                    <div>
                        MFA corporation is an ecofriendly ecosystem, the first crypto currency that supports a Techpad. This
                        ecosystem allows holders to actively get invovled in technological development and research through the
                        blockchain technology.
                    </div>
                </details>
                <details>
                    <summary>
                        Why Do We Need MFA?
                    </summary>
                    <div>
                        The MFA ecosystem is playing a very vital role in the technological world, as it serves as a breeding
                        ground for technologies and the link connecting technologist to potential investors. This may be a big step
                        in achieving more eco-friendly technology in coming years and to reduce carbon emitting technology
                    </div>
                </details>
                <details>
                    <summary>
                        How is MFA token different from other cryptocurrency?
                    </summary>
                    <div>
                        The MFA token uses technology as every other cryptocurrency, its transactions is stored in the blockchain like every other altcoin
                        but some unique features sets the MFA token apart. The MFA token exist to solve real world problems and
                        to usher in a new era of technologies.
                    </div>
                </details>
                <details>
                    <summary>
                        How Do I join community?
                    </summary>
                    <div>
                        There are several ways people participate in our online community and the most common and popular way to join
                        the MFA community is through our social Medial platform. for security purpose, use the link on the webpage to ensure
                        that you join the right community.
                    </div>
                </details>
                <details>
                    <summary>
                        How Do I get involve?
                    </summary>
                    <div>
                        Getting started with MFA ecosystem is really simple, all you need to do is set up your wallet
                        (Metamask, Trustwallet, Binancechainwallet,) and connect it to Pancakeswap to purchase MFA token.
                    </div>
                </details>
                <details>
                    <summary>
                        How Do I Stake My Token
                    </summary>
                    <div>
                        To stake your MFA token,ensure you are using a web 3 browser or a Dapp browser on your wallet.
                        Navigate to STAKING and connect your wallet, click on the stake button and get started.
                    </div>
                </details>
                <details>
                    <summary>
                        How does Minting works?
                    </summary>
                    <div>
                        Just like Staking, Minting follows the same procedures. Every token hodlers can click on the Mint function to mint the techpad Coin.
                        The Techpad coin is directly sent from the contract address to stakers wallet.
                    </div>
                </details>
                <details>
                    <summary>
                        What Are Tech Coin?
                    </summary>
                    <div>
                        Tech coins are token minted on the ecosytem that allows hodlers to participate in voting of the most creative innovations
                        displayed by technologists in the techpad space
                    </div>
                </details>
                <details>
                    <summary>
                        Are Tech Coin Tradable?
                    </summary>
                    <div>
                        Tech Coin are tradable and are relatively of higher value than the native MFA token, this is because of the low supply and high demand of
                        the Tech Coin. However, this set of tradable coin are the coins minted by stakers.
                    </div>
                </details>
            </div>
            <br />
            <br />
            <br />
            <br />
        </div>

    );

}



export default Faq;