import React from "react";
import Fundametals from "./Fundametals";
import Tech from "./Tech";
import { Helmet } from "react-helmet";

const Technology = () => {
  return (
    <div>
      <Helmet>
        <title>Xplorer Technology - Integrating Technology</title>
        <meta
          name="description"
          content="Building real world technology via the blockchain is a big leap into the future"
        />
        <meta
          name="keywords"
          content="Xplorer Token, XPT, cryptocurrency, blockchain, decentralized finance, technology"
        />
        {/* Add more meta tags as needed */}
      </Helmet>
      <Fundametals />
      <br />
      <br />
      <Tech />
    </div>
  );
};

export default Technology;
