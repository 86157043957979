import React from "react";

import loaderIcon from "../images/loader.png";
const Spinner = () => {
  return (
    <div className="loader">
      <img src={loaderIcon} height={80} width={80} />
    </div>
  );
};

export default Spinner;
