import React from "react";
import { Link } from "react-router-dom";

const FutureVision = () => {
  return (
    <div
      style={{
        background: "black",
      }}
    >
      <h4 className="h1-about">Future Vision</h4>
      <p className="about-p">
        Xplorer is not just a platform; it's a vision for the future of
        technology and innovation. Our long-term goals revolve around creating a
        sustainable ecosystem that fosters collaboration, creativity, and
        positive impact. Here's a glimpse into the future we envision:
      </p>
      <h4 className="h1-about">Innovative Technologies</h4>
      <p className="about-p">
        We aim to be at the forefront of technological advancements. Expect to
        see the incubation and launch of cutting-edge technologies that address
        real-world challenges, pushing the boundaries of what's possible.
      </p>
      <h4 className="h1-about">Global Impact</h4>
      <p className="about-p">
        Xplorer is committed to making a positive global impact. We're exploring
        partnerships and initiatives that go beyond borders, leveraging
        technology to bring about positive change in various industries and
        communities.
      </p>
      <h4 className="h1-about">Environmental Sustainability</h4>
      <p className="about-p">
        Xplorer takes pride in its commitment to environmental sustainability.
        We are actively working towards a greener future, implementing
        eco-friendly practices, and exploring initiatives such as carbon offset
        programs and support for green technologies. Our goal is not just
        technological innovation but doing so in a way that benefits the planet
        we call home.
      </p>
      <h4 className="h1-about">Community Collaboration</h4>
      <p className="about-p">
        The Xplorer community is the driving force behind our vision. We
        envision a vibrant and engaged community actively contributing ideas,
        participating in governance, and shaping the direction of the platform.
      </p>
      <h4 className="h1-about">Decentralized Innovation</h4>
      <p className="about-p">
        As we move forward, decentralization remains a core principle. We're
        working on features and mechanisms that empower the community, ensuring
        that decisions are collectively made for the benefit of all
        stakeholders.
      </p>
      <h4 className="h1-about">Strategic Partnerships</h4>
      <p className="about-p">
        Stay tuned for exciting collaborations and partnerships that align with
        our vision. These partnerships will play a crucial role in expanding our
        reach and bringing valuable resources to the Xplorer ecosystem.
      </p>
      <h4 className="h1-about">Continuous Evolution</h4>
      <p className="about-p">
        The future is dynamic, and so is Xplorer. We're committed to evolving
        with the rapidly changing landscape of technology and blockchain. Our
        roadmap includes regular updates and enhancements to keep the platform
        at the forefront of innovation.
      </p>
      <h4 className="h1-about">Social Impact</h4>
      <p className="about-p">
        Xplorer recognizes its role in contributing to societal well-being. We
        are dedicated to supporting the younger generation through initiatives
        that focus on education, skill development, and community welfare. Stay
        tuned for programs that aim to make a positive social impact and empower
        individuals for a brighter future.
      </p>
      <Link to={`/Roadmap`}>
        <button className="button">Roadmap</button>
      </Link>
    </div>
  );
};

export default FutureVision;
