import React from "react";
import { Helmet } from "react-helmet";

const RoadmapDetails = () => {
  return (
    <div>
      <Helmet>
        <title>Xplorer Roadmap - Beyon DeFi</title>
        <meta name="description" content="Details of Xplorer Token (XPT)" />
        <meta
          name="keywords"
          content="Xplorer Token, XPT, cryptocurrency, blockchain, decentralized finance, technology"
        />
        {/* Add more meta tags as needed */}
      </Helmet>{" "}
      <div className="about">
        <h1 className="h1-about">Roadmap to impact</h1>
        <p className="about-p">
          Embark on a groundbreaking journey with Xplorer as we unfold our
          visionary roadmap, a testament to our commitment to revolutionizing
          technology development. Across six meticulously crafted phases, we
          navigate the realms of innovation, decentralization, and
          community-driven impact. From laying the foundational stones in our
          Initialization and Launch phase to the ongoing pursuit of Continuous
          Innovation, each stage is a testament to our unwavering dedication to
          pushing the boundaries of what's possible. Join us on this
          exhilarating odyssey, where the future of impactful technology
          development is not just imagined but meticulously crafted, one phase
          at a time. Welcome to Xplorer - where innovation meets purpose.
        </p>
      </div>
      <div className="timeline">
        <h2 id="h2"> Launch Roadmap 2024</h2>
        <div className="timeline-row">
          <div className="timeline-time">
            Phase 1: Initialization and Launch
          </div>
          <div className="timeline-content">
            <p>
              In the inaugural phase, Xplorer embarks on its journey, laying the
              groundwork for the revolutionary venture. This period is dedicated
              to team formation, conceptualizing the platform's core features,
              and building the foundational infrastructure. The Xplorer
              community starts to take shape as initial outreach efforts begin.
              The primary goal is to set a robust foundation for what's to come.
            </p>
            <i className="icon-attachment"></i>
            <h4>Key Milestones:</h4>
            <ul>
              <li>
                Team Formation: Assembling a dedicated team of visionaries,
                developers, and strategists.
              </li>{" "}
              <li>
                Conceptualization: Defining the core features and
                functionalities of the Xplorer platform.{" "}
              </li>{" "}
              <li>
                {" "}
                Infrastructure Setup: Establishing the technological backbone
                for seamless future development.
              </li>{" "}
              <li>
                {" "}
                Community Outreach: Initial engagement with potential users and
                early adopters.
              </li>
            </ul>
            <div className="">
              <span className="badge badge-pill">Initiating the Journey</span>
            </div>
          </div>
        </div>

        <div className="timeline-row">
          <div className="timeline-time">Phase 2: Utility Roll Ups</div>
          <div className="timeline-content">
            <p>
              With a solid foundation, Xplorer dives into developing and
              launching its core utilities. The TechPad, an innovative hub for
              crowdfunding, incubation, and technology launches, takes center
              stage. Smart contracts and protocols are implemented to ensure a
              secure and efficient environment for users. The Xplorer token
              (XPT) becomes an integral part of the ecosystem, facilitating
              transactions and participation in various activities.
            </p>
            <i className="icon-code"></i>
            <h4>Key milestones</h4>
            <ul>
              <li>
                TechPad Development: Creating a user-friendly platform for
                crowdfunding and incubation.{" "}
              </li>{" "}
              <li>
                Smart Contract Implementation: Ensuring the security and
                transparency of transactions.{" "}
              </li>{" "}
              <li>
                {" "}
                XPT Integration: Introduction of the Xplorer token to fuel
                ecosystem transactions.{" "}
              </li>{" "}
              <li>
                {" "}
                Initial Utility Rollouts: Launching the first set of utilities
                on the TechPad.{" "}
              </li>
            </ul>
            <div className="">
              <span className="badge badge-pill">Building the Ecosystem</span>
            </div>
          </div>
        </div>

        <div className="timeline-row">
          <div className="timeline-time">Phase 3: Ecosystem Expansion</div>
          <div className="timeline-content">
            <p>
              As the Xplorer ecosystem gains traction, focus shifts to expansion
              and diversification. Strategic partnerships are formed to enhance
              the ecosystem's offerings. The community actively participates in
              shaping the platform's direction through decentralized governance.
              The TechFi concept evolves with additional utilities, creating a
              comprehensive ecosystem that extends beyond initial expectations.
            </p>
            <i className="icon-turned_in_not"></i>
            <h4> Key Milestones</h4>
            <ul>
              <li>
                Partnership Building: Collaborating with key players to enrich
                the ecosystem. Decentralized Governance:
              </li>
              <li>
                {" "}
                Empowering the community to actively contribute to
                decision-making.{" "}
              </li>
              <li>
                TechFi Evolution: Expanding the TechPad concept with new
                utilities and innovative features.{" "}
              </li>
              <li>
                {" "}
                Global Outreach: Increasing visibility and user base on a global
                scale.
              </li>
            </ul>
            <div>
              <span className="badge badge-pill">Scaling Horizons</span>
            </div>
          </div>
        </div>

        <div className="timeline-row">
          <div className="timeline-time">Phase 4: Blockchain Development</div>
          <div className="timeline-content">
            <p>
              To solidify its position as a trailblazer, Xplorer intensifies
              efforts in blockchain development. Upgrades and optimizations are
              implemented to enhance scalability, security, and
              interoperability. Integration with emerging blockchain
              technologies ensures Xplorer remains at the forefront of
              innovation.
            </p>
            <i className="icon-directions"></i>
            <h4> Key Milestones</h4>
            <ul>
              <li>
                Blockchain Optimization: Enhancing scalability and performance
                for a seamless user experience.{" "}
              </li>
              <li>
                Interoperability Features: Integrating with other blockchain
                networks for enhanced functionality.{" "}
              </li>
              <li>
                Security Measures: Implementing robust security protocols to
                safeguard user assets.{" "}
              </li>
              <li>
                Technology Upgrades: Staying aligned with the latest
                advancements in blockchain technology.
              </li>
            </ul>
          </div>
        </div>
        <div className="timeline-row">
          <div className="timeline-time">Phase 5: Mainstream Adoption</div>
          <div className="timeline-content">
            <p>
              Global Impact and Recognition As Xplorer matures, the focus shifts
              towards achieving mainstream adoption. Marketing initiatives are
              intensified, targeting a broader audience. Educational programs
              and outreach campaigns aim to familiarize the public with the
              benefits of the Xplorer ecosystem. The platform becomes synonymous
              with impactful technology development, gaining recognition on a
              global scale.
            </p>
            <i className="icon-attachment"></i>
            <h4>Key Milestones</h4>
            <li>
              {" "}
              Global Marketing Campaigns: Promoting Xplorer and its utilities to
              a wider audience.{" "}
            </li>{" "}
            <li>
              Educational Initiatives: Informing the public about the advantages
              of participating in the Xplorer ecosystem.{" "}
            </li>{" "}
            <li>
              Mainstream Recognition: Establishing Xplorer as a leading force in
              impactful technology development.
            </li>{" "}
            <li>
              User Onboarding: Simplifying the onboarding process to attract
              users from diverse backgrounds.
            </li>
          </div>
        </div>
        <div className="timeline-row">
          <div className="timeline-time">Phase 6: Continuous Innovation</div>
          <div className="timeline-content">
            <p>
              Beyond the defined phases, Xplorer remains committed to continuous
              innovation. The platform adapts to emerging trends, incorporates
              user feedback, and explores new frontiers in technology. The
              community becomes a driving force for innovation, ensuring Xplorer
              remains a dynamic and influential player in the ever-evolving
              landscape of decentralized technology development.
            </p>
            <i className="icon-directions"></i>
            <h4>Key Focus Areas</h4>
            <ul>
              <li>
                Adaptation to Trends: Remaining agile and responsive to changes
                in the technological landscape.{" "}
              </li>{" "}
              <li>
                Community-Driven Innovation: Encouraging and implementing ideas
                from the user community.{" "}
              </li>
              <li>
                Exploration of New Frontiers: Venturing into uncharted
                territories to pioneer novel concepts.
              </li>{" "}
              <li>
                {" "}
                Sustainability Practices: Integrating eco-friendly practices and
                contributing to societal well-being.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoadmapDetails;
