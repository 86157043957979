import React from 'react'
import Community from './Community'
import OnlineCommunity from './OnlineCommunity'

const CommunityContainer = () => {
    return (
        <div><Community />
            <br />
            <OnlineCommunity />
        </div>
    )
}

export default CommunityContainer