import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import {
  FiAlignRight,
  FiChevronsDown,
  FiMenu,
  FiXCircle,
} from "react-icons/fi";
import logo from "../images/logo.png";

const Navbarmenu = () => {
  const [isMenu, setisMenu] = useState(false);
  const [isResponsiveclose, setResponsiveclose] = useState(false);
  const toggleClass = () => {
    setisMenu(isMenu === false ? true : false);
    setResponsiveclose(isResponsiveclose === false ? true : false);
  };

  let boxClass = ["main-menu menu-right menuq1"];
  if (isMenu) {
    boxClass.push("menuq2");
  } else {
    boxClass.push("");
  }
  const [isMenuSubMenu, setMenuSubMenu] = useState(false);

  const toggleSubmenu = () => {
    setMenuSubMenu(isMenuSubMenu === false ? true : false);
  };

  let boxClassSubMenu = ["sub__menus"];
  if (isMenuSubMenu) {
    boxClassSubMenu.push("sub__menus__Active");
  } else {
    boxClassSubMenu.push("");
  }

  return (
    <header className="header__middle">
      <div className="container">
        <div className="row">
          {/* Add Logo  */}
          <div className="header__middle__logo">
            <img className="logo" src={logo} alt="logo" />
            <NavLink exact activeclassname="is-active" to="/"></NavLink>
          </div>

          <div className="header__middle__menus">
            <nav className="main-nav ">
              {/* Responsive Menu Button */}
              {isResponsiveclose === true ? (
                <>
                  <span
                    className="menubar__button"
                    style={{ display: "none" }}
                    onClick={toggleClass}
                  >
                    {" "}
                    <FiXCircle />{" "}
                  </span>
                </>
              ) : (
                <>
                  <span
                    className="menubar__button"
                    style={{ display: "none" }}
                    onClick={toggleClass}
                  >
                    {" "}
                    <FiMenu />{" "}
                  </span>
                </>
              )}

              <ul className={boxClass.join(" ")}>
                <li className="menu-item">
                  <NavLink
                    exact
                    activeclassname="is-active"
                    onClick={toggleClass}
                    to={`/`}
                  >
                    {" "}
                    Home{" "}
                  </NavLink>
                </li>
                <li className="menu-item ">
                  <NavLink
                    onClick={toggleClass}
                    activeclassname="is-active"
                    to={`/Defi`}
                  >
                    {" "}
                    DEFI{" "}
                  </NavLink>{" "}
                </li>
                <li className="menu-item ">
                  <NavLink
                    onClick={toggleClass}
                    activeclassname="is-active"
                    to={`/Technology`}
                  >
                    {" "}
                    Technology{" "}
                  </NavLink>{" "}
                </li>
                <li className="menu-item ">
                  <NavLink
                    onClick={toggleClass}
                    activeclassname="is-active"
                    to={`/xplorer-token`}
                  >
                    {" "}
                    XPT
                  </NavLink>{" "}
                </li>
                <li className="menu-item ">
                  <NavLink
                    onClick={toggleClass}
                    activeclassname="is-active"
                    to={`/Community`}
                  >
                    Community{" "}
                  </NavLink>{" "}
                </li>
                <li className="menu-item ">
                  <NavLink
                    onClick={toggleClass}
                    activeclassname="is-active"
                    to={`/Roadmap`}
                  >
                    Roadmap{" "}
                  </NavLink>{" "}
                </li>
                {/*
                <li className="menu-item ">
                  {" "}
                  <a href="https://drive.google.com/u/0/uc?id=1rX0kmTdXVsQVF2PVeRy4zsgPnortRteq&export=download">
                    Whitepaper
                  </a>
              </li>*/}
              </ul>
            </nav>
          </div>
        </div>
        <a href="https://dapp.xplorertoken.space">
          <button className="dapp">Launch Dapp</button>
        </a>
      </div>
    </header>
  );
};

export default Navbarmenu;
