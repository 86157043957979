import React from "react";

import { mind, everyone, investors } from "../../images";

const OnlineCommunity = () => {
  return (
    <div className="main-container">
      <div className="one-second-animation">
        <div>
          <h1 className="h1">
            XPT Online
            <br /> Community
          </h1>
          <h2 className="about-p">
            Join our Amazing online community to participate in events capable
            of changing the world and enjoy the company of other passionate
            techologists helping forge the future of technology.
          </h2>
        </div>
        <br />
        <br />

        <div className="technology" style={{ background: "black" }}>
          <div id="grid">
            <div className="grid-image">
              <img className="tech-images" src={everyone} alt="Everyone" />
            </div>
            <div className="grid-image-mobile">
              <img className="tech-images" src={everyone} alt="Everyone" />
            </div>
            <div className="grid-content">
              <h2 id="h2">Xplorer for Visionaries</h2>
              <p>
                Embark on the journey towards innovative solutions and shape the
                future of technology. Xplorer welcomes everyone, including
                crypto enthusiasts, content creators, NFT artists and
                collectors, crypto newbies, investors, and anyone passionate
                about influencing the world through technology. Every idea and
                contribution, no matter how small, can contribute to
                transforming the world's technology landscape.
              </p>
              <h3 id="h3">Your Ideas Matter</h3>
            </div>
          </div>

          <div id="grid">
            <div className="grid-image-mobile">
              <img className="tech-images" src={mind} alt="Mind" />
            </div>
            <div className="grid-content">
              <h2 id="h2">Xplorer for Technologists</h2>
              <p>
                In a world driven by technology, the Xplorer ecosystem empowers
                technologists to explore cutting-edge technologies worldwide. It
                provides a fast and effective platform for collaboration,
                leveraging collective efforts to find solutions to technological
                challenges. Join a global community of passionate technologists,
                connect, and contribute to building the next generation of
                transformative technology.
              </p>
              <h3>Recognition for Your Innovations</h3>
            </div>
            <div className="grid-image">
              <img className="tech-images" src={mind} alt="Mind" />
            </div>
          </div>

          <div id="grid">
            <div className="grid-image">
              <img className="tech-images" src={investors} alt="Investors" />
            </div>
            <div className="grid-image-mobile">
              <img className="tech-images" src={investors} alt="Investors" />
            </div>
            <div className="grid-content">
              <h2 id="h2">Xplorer for Investors</h2>
              <p>
                Explore Xplorer to discover new and promising technologies from
                around the world. Our Techpad voting mechanism allows investors
                to identify technologies with high potential for profitable
                investment. By voting, investors create demand for specific
                technologies, ensuring a secure and informed investment process.
              </p>
              <h3>Secure and Informed Investment</h3>
            </div>
          </div>
        </div>

        <div className=" social-community insight">
          <ul className="contain">
            <li className="">
              <a
                href="http://twitter.com/xplorertoken"
                target="_blank"
                title="Twitter"
                className="socials-item"
              >
                <i className="fab fa-twitter twitter"></i>
              </a>
              <h2 id="h2">Twitter Community</h2>
            </li>
            <li className="">
              <a
                href="http://t.me/xplorertoken"
                target="_blank"
                title="Telegram"
                className="socials-item"
              >
                <i className="fab fa-telegram telegram"></i>
              </a>
              <h2 id="h2"> Telegram Community</h2>
            </li>
            <li className="">
              <a
                href="https://www.discord.gg/HwUPAHQFNM"
                target="_blank"
                title="discord"
                className="socials-item"
              >
                <i className="fab fa-discord discord"></i>
              </a>
              <h2 id="h2">Discord Community</h2>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default OnlineCommunity;
