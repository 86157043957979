// Import React and other required modules
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

// Define your component
const ScrollToTop = () => {
  // Get the current location using useLocation from react-router-dom
  const { pathname } = useLocation();

  // Scroll to the top when the location changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]); // Trigger the effect whenever the pathname changes

  // Return null because this component doesn't render anything
  return null;
};

// Export the component
export default ScrollToTop;
