import React from "react";
import {
  tech,
  begin,
  CB,
  transition,
  bridge,
  blockchain,
  development,
  discover,
} from "../../images";

const Fundamentals = () => {
  return (
    <div>
      <h1 className="h1">
        A Fast, Efficient Way <br />
        To Build Technologies
      </h1>

      <br />
      <br />

      <div className="about">
        <h2 id="h2">WHAT IS XPLORER?</h2>
        <p className="about-p">
          Xplorer is a groundbreaking blockchain platform that redefines the
          intersection of cryptocurrency and technology. Designed to usher in a
          new era of innovation, Xplorer leverages TechFi, seamlessly
          integrating decentralized applications (dApps) to create a
          collaborative ecosystem. Xplorer aims to bridge the gap between
          traditional technology development and the world of cryptocurrency,
          fostering a global community of technologists, investors, and
          enthusiasts. With a commitment to positive impact, Xplorer is set to
          revolutionize the way technologies are funded, incubated, and
          launched, setting the stage for continuous advancements and
          transformative solutions.
        </p>
      </div>
    </div>
  );
};

export default Fundamentals;
