import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const Roadmap = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="about">
      <h2 className="h1"> Building Together</h2>
      <p className="about-p">
        Our community-driven roadmap propels Xplorer to the forefront of
        blockchain technology. Transparent and dynamic, we celebrate milestones
        quarterly, engaging Hodlers with exciting contests. Step into a world
        where constant technological evolution addresses humanity's challenges
        with cutting-edge solutions.
      </p>
      <Link to={`/Roadmap`}>
        <button className="button">Exploer Roadmap</button>
      </Link>
    </div>
  );
};

export default Roadmap;
