import React from "react";
import { Link } from "react-router-dom";

// Your image imports
import headerImage from "../../images/loader.png";
import defiImage from "../../images/doorway.jpg";
import technologyImage from "../../images/building.jpg";
import communityImage from "../../images/build.jpg";
import xplorerTokenImage from "../../images/sunrise.jpg";
import { FiArrowRightCircle } from "react-icons/fi";

const Utility = () => {
  const sections = [
    {
      title: "DeFi",
      introText:
        "Discover the decentralized financial ecosystem powered by Xplorer. Explore staking, lending, and more.",
      image: defiImage,
      path: "/defi", // Define the path for DeFi
    },
    {
      title: "Technology",
      introText:
        "Immerse yourself in cutting-edge technologies fostered by Xplorer. Join the innovation revolution.",
      image: technologyImage,
      path: "/technology", // Define the path for Technology
    },
    {
      title: "Community",
      introText:
        "Become a part of the vibrant Xplorer community. Engage, collaborate, and contribute to shaping the future.",
      image: communityImage,
      path: "/community", // Define the path for Community
    },
    {
      title: "Xplorer Token",
      introText:
        "Unleash the potential of XPT - the driving force behind Xplorer. Learn how it empowers and transforms.",
      image: xplorerTokenImage,
      path: "/xplorer-token", // Define the path for Xplorer Token
    },
  ];

  return (
    <div className="grid-container">
      {sections.map((section, index) => (
        <div key={index} className="grid-item">
          <Link to={section.path}>
            <img
              src={section.image}
              alt={section.title}
              className="grid-container-image"
            />
            <h2>{section.title}</h2>
            <p>{section.introText}</p>

            <button className="learn-more-btn">
              <FiArrowRightCircle />
            </button>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default Utility;
