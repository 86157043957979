import React from "react";
import { Helmet } from "react-helmet";
import { blockchain, funding, marketplace, protocol } from "../../images";
import { Link } from "react-router-dom";
import { FiArrowRightCircle } from "react-icons/fi";

const Defi = () => {
  return (
    <div className="one-second-animation">
      <Helmet>
        <title>Xplorer DeFi - Built for Finance</title>
        <meta
          name="description"
          content="Expirence decentralized finance like no other, low cost and faster Transaction"
        />
        <meta
          name="keywords"
          content="Xplorer Token, XPT, cryptocurrency, blockchain, decentralized finance, technology"
        />
        {/* Add more meta tags as needed */}
      </Helmet>
      <div id="projects-grid">
        <div className="project-divs">
          <img className="img-tech" src={protocol} />
          <h1
            style={{ textAlign: "left", padding: "1rem 0", fontSize: "2rem" }}
          >
            XPT Vault
          </h1>
          <p>
            Immerse yourself in the XPT Vault and unlock the full potential of
            your Xplorer Tokens! Staking in our vault goes beyond earning
            passive tokens – it opens the door to a generous 50% APY (Annual
            Percentage Yield). This translates to a steady income flow, simply
            by holding XPT. Your pathway to financial growth starts right here.
          </p>
          <a href="https://dapp.xplorertoken.space/#/Stake">
            <button className="learn-more-btn">
              <FiArrowRightCircle />
            </button>
          </a>
        </div>
        <div className="project-divs">
          <img className="img-tech" src={marketplace} />
          <h1
            style={{ textAlign: "left", padding: "1rem 0", fontSize: "2rem" }}
          >
            Governance
          </h1>
          <p>
            At Xplorer, we believe in decentralized power. XPT token holders are
            the true architects of our platform. Through the governance feature,
            you, as a holder, have complete control over the destiny of Xplorer.
            Participate in decentralized voting, influence protocol upgrades,
            and shape the future of our ecosystem. XPT holders are not just
            stakeholders; they are the real owners driving Xplorer's journey to
            new heights.
          </p>
          <a href="https://dapp.xplorertoken.space/#/">
            <button className="learn-more-btn">
              <FiArrowRightCircle />
            </button>
          </a>
        </div>
        <div className="project-divs">
          <img className="img-tech" src={funding} />
          <h1
            style={{ textAlign: "left", padding: "1rem 0", fontSize: "2rem" }}
          >
            Lending
          </h1>
          <p>
            Unlock the power of your XPT holdings! With Xplorer's lending
            platform, you can use your XPT as collateral to borrow other assets.
            Whether you're looking to leverage your holdings or explore new
            investment opportunities, our lending platform provides a flexible
            and efficient way to meet your financial needs.
          </p>
        </div>
        <div className="project-divs">
          <img className="img-tech" src={blockchain} />
          <h1
            style={{ textAlign: "left", padding: "1rem 0", fontSize: "2rem" }}
          >
            Crowdfunding
          </h1>
          <p>
            Join the Xplorer Crowdfunding revolution! With our platform,
            innovators and visionaries can present their projects to the Xplorer
            community, seeking support for their groundbreaking ideas. Xplorer
            Crowdfunding is not just about funding; it's a collaborative journey
            where the community actively engages in shaping the future of
            technology. Explore exciting projects, contribute to innovations,
            and be part of the Xplorer impact.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Defi;
