import React from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Roadmap from "./Roadmap";
import Tech from "./Technology/Tech";
import world from "../images/connection.jpg";

import {
  tech,
  begin,
  CB,
  transition,
  bridge,
  blockchain,
  development,
  privacy,
  mind,
} from "../images";
import LandingPage from "./LandingPage";
import Features from "./Features";
import Utility from "./UseCase/Utility";
import Utilities from "./Utility";

const Home = () => {
  const navigate = useNavigate();

  const HandleLearnMore = () => {
    navigate("/technology");
  };
  return (
    <div>
      <div id="home">
        {" "}
        <h1 className="glitch text">The Revolution</h1>
        <br />
        <div id="home-div inline">
          <div classame="main-header inline">
            <h2 className="h2">
              {" "}
              Embark on a journey of innovation and growth. Xplorer is more than
              a funding platform; it's a community of visionary entrepreneurs
              and forward-thinking investors.
            </h2>
            <br />
            <span>
              {" "}
              <button onClick={HandleLearnMore} className="button inline">
                Learn More
              </button>
            </span>
          </div>
        </div>
      </div>
      <div style={{ background: "black", padding: "50px 0" }}>
        <div className="header">
          <img className="header-image" src={world} />
          <p>
            In the dynamic realm of blockchain evolution, Xplorer is embarking
            on a visionary quest with the introduction of TechFi. Beyond the
            established norms of DeFi and GameFi, TechFi represents a paradigm
            shift, integrating advanced technologies and decentralized
            applications (DApps) into the very fabric of Xplorer's ecosystem.
          </p>
        </div>
        <div id="projects-grid">
          <div className="project-divs">
            <h2 className="h2-standard individual-resume-divs-headers">
              TechFi Genesis
            </h2>
            <div className="resume-bullet-points-div">
              <p className="resume-bullet-points">
                {" "}
                As Xplorer unveils TechFi, a new chapter unfolds in the world of
                cryptocurrency. TechFi transcends the conventional boundaries of
                decentralized finance, aiming to create a symbiotic relationship
                between the crypto community and the ever-expanding landscape of
                technology development.
              </p>
            </div>
          </div>

          <div className="project-divs">
            <h2 className="h2-standard individual-resume-divs-headers">
              {" "}
              The DApp Revolution
            </h2>
            <div className="resume-bullet-points-div">
              <p className="resume-bullet-points">
                At the heart of TechFi lies the powerful integration of
                decentralized applications. Xplorer envisions a future where
                DApps become not just financial disruptors but catalysts for
                innovation across diverse industries. The integration of these
                applications into the Xplorer ecosystem elevates the platform to
                new heights.
              </p>
            </div>
          </div>

          <div className="project-divs">
            <h2 className="h2-standard individual-resume-divs-headers">
              Reshaping the Digital Landscape
            </h2>
            <div className="resume-bullet-points-div">
              <p className="resume-bullet-points">
                Much like Ethereum's transformative impact on the internet,
                TechFi positions Xplorer as a catalyst for change. This
                innovative approach extends beyond financial applications,
                shaping the internet landscape with decentralized solutions that
                prioritize transparency, security, and efficiency.
              </p>
            </div>
          </div>

          <div className="project-divs">
            <h2 className="h2-standard individual-resume-divs-headers">
              A Glimpse into the Future
            </h2>
            <div className="resume-bullet-points-div">
              <p className="resume-bullet-points">
                In Xplorer's visionary narrative, TechFi unfolds a future where
                technological breakthroughs are not sporadic but a constant. The
                platform envisions a world where novel solutions emerge
                continuously, addressing the evolving needs of humanity. TechFi
                becomes the driving force behind this transformative journey.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/*<div>
        <h2 id="h2">Xplorer Features</h2>
        <div className="insight">
          <div className="contain">
            <div className="grid">
              <img className="img" src={begin} alt="" />
              <h2 id="h2"> Innovation </h2>
              <p>
                Fueling groundbreaking ideas that have the potential to reshape
                industries.
              </p>
            </div>

            <div className="grid">
              <img className="img" src={CB} alt="" />
              <h2 id="h2"> Inclusivity </h2>
              <p>
                Providing equal opportunities for startups of all sizes and
                backgrounds.
              </p>
              <p></p>
            </div>

            <div className="grid">
              <img className="img" src={privacy} alt="" />
              <h2 id="h2"> Transparency </h2>
              <p>
                Ensuring openness in funding processes and clear communication.
              </p>
            </div>
          </div>
        </div>
  </div> */}
      <Utility />

      <br />
      <br />
      <Utilities />
      <br />
      <br />

      <Roadmap />
    </div>
  );
};

export default Home;
