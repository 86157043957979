import React, { useEffect } from "react";
import XPT from "../components/XPT";

const MFATContainer = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div class="main-container">
      <div className="one-second-animation">
        <XPT />
      </div>
    </div>
  );
};

export default MFATContainer;
