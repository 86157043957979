import React, { useEffect, useState } from 'react'


function ScrollButton() {
    const [scrollButton, setScrollButton] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 100) {
                setScrollButton(true)
            } else {
                setScrollButton(false)
            }
        })
    }, [])

    const scrollUp = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }
    return (
        <div>
            {scrollButton && (
                <button className='arrow' onClick={scrollUp}>^ </button>
            )}
        </div>
    );
}

export default ScrollButton;