import React from "react";
import { involved } from "../images";

const GetInvolve = () => {
  return (
    <div>
      {" "}
      <div className="about">
        <img className="photo" src={involved} alt="" />
        <h2 id="h2">Get started with Xplorer</h2>
        <p className="about-p">
          Ready to join the Xplorer revolution? Getting started is easy! Simply
          download a crypto wallet of your choice, purchase XPT on any
          decentralized (DEX) or centralized (CEX) exchange, and transfer it to
          your wallet. Join our vibrant community as we collaboratively build
          the future of technology together.
        </p>
      </div>
    </div>
  );
};

export default GetInvolve;
