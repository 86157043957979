export const MFA = require("../images/logo.png");
export const flow = require("../images/flow.png");
export const governance = require("../images/governance.png");
export const difference = require("../images/difference.png");
export const connect = require("../images/connect.png");
export const involved = require("../images/involved.png");
export const marketplace = require("../images/marketplace.png");
export const team = require("../images/team.png");
export const privacy = require("../images/privacy.png");
export const logo = require("../images/xplorer.png");
export const community = require("../images/community.png");
export const certified = require("../images/certified.png");
export const funding = require("../images/crowd funding.png");
export const minting = require("../images/minting.png");
export const tech = require("../images/techpad.png");
export const staking = require("../images/staking.png");
export const protocol = require("../images/protocol.png");
export const CB = require("../images/connect and build.png");
export const utility = require("../images/cloud.png");
export const begin = require("../images/begining.png");
export const bridge = require("../images/bridge.png");
export const integrate = require("../images/integrate.png");
export const mind = require("../images/mind.png");
export const transition = require("../images/transition.png");
export const discover = require("../images/discover.jpg");
export const immerse = require("../images/immerse.png");
export const integrating = require("../images/integrating.png");
export const using = require("../images/using.png");
export const blockchain = require("../images/blockchain.png");
export const investors = require("../images/investors.png");
export const everyone = require("../images/everyone.png");
export const development = require("../images/development.png");
export const techpad = require("../images/Techpadbg.png");
export const token3D = require("../images/xpt.png");
export const insight = require("../images/insight.jpeg");
export const gbk = require("../images/gbk.png");
export const open = require("../images/open.png");
export const innovation = require("../images/innovation.png");
export const energy = require("../images/energy.png");
export const onchain = require("../images/onchain.png");
export const scalability = require("../images/scalability.png");
export const green = require("../images/green.png");
