import React from "react";
import {
  gbk,
  open,
  green,
  energy,
  onchain,
  scalability,
  innovation,
  immerse,
  integrating,
  using,
  discover,
} from "../../images";

class Tech extends React.Component {
  state = {
    showStudentRepresentativeCouncil: false,
    showSanFranciscoBoxOffice: false,
    showBerlitz: false,
    showAMSAB: false,
    showMuseumOfEdinburgh: false,
    showStudentRepresentativeCouncil: false,
  };

  handleExpand = (event) => {
    this.setState({
      [event.target.id]: !this.state[event.target.id],
    });
  };

  render() {
    return (
      <div className="one-second-animation">
        <br />
        <div className="bg2">
          <h2 className="h2">AT THE CORE</h2>

          <div id="projects-grid">
            <div
              className="project-divs"
              id="showStudentRepresentativeCouncil"
              onClick={this.handleExpand}
            >
              <img className="img-tech" src={open} alt="" />
              <h2
                className="h2-standard individual-resume-divs-headers"
                id="showStudentRepresentativeCouncil"
                onClick={this.handleExpand}
              >
                Open Source
              </h2>
              <div className="resume-bullet-points-div">
                <p className="resume-bullet-points">
                  {" "}
                  Xplorer's mission is to build a technological platform that is
                  open to technologists worldwide, providing unlimited access to
                  our techpad experience. Anyone aspiring to build, participate,
                  or receive grants for technological startups is welcome to
                  join Xplorer.
                </p>
              </div>
            </div>

            <div
              className="project-divs"
              id="showStudentRepresentativeCouncil"
              onClick={this.handleExpand}
            >
              <img className="img-tech" src={energy} alt="" />
              <h2
                className="h2-standard individual-resume-divs-headers"
                id="showStudentRepresentativeCouncil"
                onClick={this.handleExpand}
              >
                {" "}
                New Development Frontier
              </h2>
              <div className="resume-bullet-points-div">
                <p className="resume-bullet-points">
                  The Techpad app powered by the Xplorer token is transparent
                  and open to all. It provides access to new technologies,
                  offering technologists ideas to build upon, reducing
                  development time and fostering collaborative efforts.
                </p>
              </div>
            </div>

            <div
              className="project-divs"
              id="showMarketHallCaterers"
              onClick={this.handleExpand}
            >
              <img className="img-tech" src={gbk} alt="" />
              <h2
                className="h2-standard individual-resume-divs-headers"
                id="showMarketHallCaterers"
                onClick={this.handleExpand}
              >
                Groundbreaking Technology
              </h2>
              <div className="resume-bullet-points-div">
                <p className="resume-bullet-points">
                  Xplorer innovates its techpad to build a base platform for
                  groundbreaking technologies that address technological
                  limitations and promote advancement in remote areas.
                </p>
              </div>
            </div>

            <div
              className="project-divs"
              id="showSanFranciscoBoxOffice"
              onClick={this.handleExpand}
            >
              <img className="img-tech" src={green} alt="" />
              <h2
                className="h2-standard individual-resume-divs-headers"
                id="showSanFranciscoBoxOffice"
                onClick={this.handleExpand}
              >
                Green Energy Protocol
              </h2>
              <div className="resume-bullet-points-div">
                <p className="resume-bullet-points">
                  {" "}
                  Xplorer embarks on a quest for green energy and a carbon-free
                  environment. The Techpad protocol enables the building of
                  green energy solutions, incubating and refining newly
                  discovered technologies before crowdfunding.
                </p>
              </div>
            </div>

            <div
              className="project-divs"
              id="showAMSAB"
              onClick={this.handleExpand}
            >
              <img className="img-tech" src={onchain} alt="" />
              <h2
                className="h2-standard individual-resume-divs-headers"
                id="showAMSAB"
                onClick={this.handleExpand}
              >
                Technology on-chain
              </h2>
              <div className="resume-bullet-points-div">
                <p className="resume-bullet-points">
                  {" "}
                  Xplorer enables the building of real-world technology on the
                  blockchain. Technology on-chain allows technologists worldwide
                  to build, transfer, and receive blueprints of already
                  developed technologies, increasing efficiency.
                </p>
              </div>
            </div>

            <div
              className="project-divs"
              id="showBerlitz"
              onClick={this.handleExpand}
            >
              <img className="img-tech" src={scalability} alt="" />
              <h2
                className="h2-standard individual-resume-divs-headers"
                id="showBerlitz"
                onClick={this.handleExpand}
              >
                Technology Scalability
              </h2>
              <div className="resume-bullet-points-div">
                <p className="resume-bullet-points">
                  Scale your newly developed technology and reach a wide range
                  of technology users through the Xplorer Techpad{" "}
                </p>
              </div>
            </div>

            <div
              className="project-divs"
              id="showMuseumOfEdinburgh"
              onClick={this.handleExpand}
            >
              <img className="img-tech" src={innovation} alt="" />
              <h2
                className="h2-standard individual-resume-divs-headers"
                id="showMuseumOfEdinburgh"
                onClick={this.handleExpand}
              >
                Easy Technology Innovation
              </h2>
              <div className="resume-bullet-points-div">
                <p className="resume-bullet-points">
                  {" "}
                  The innovation of the Xplorer Techpad interface is easy to use
                  and accessible to all smart device users.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <div className="intro">
          <img className="img-intro" src={discover} alt="" />
          <h1 className="h1-about2">
            Discover New Technologies
            <br /> with Xplorer
          </h1>
          <div>
            <p className="p-intro">
              {" "}
              Xplorer is a cryptocurrency-powered platform that actively
              involves people in technological development, going beyond being
              just users.
            </p>
            <br />
            <p className="p-intro">
              Xplorer provides a real-world use case for its cryptocurrency,
              allowing people to leverage ideas and effort to improve
              technologies.
            </p>
            <br />
            <p className="p-intro">
              Xplorer exposes technologists to a large audience (the crypto
              community and non-crypto users) to gain public attention and
              support for newly created technologies. This enables constant
              innovation discovery and global support.
            </p>
          </div>
          <br />
        </div>

        <br />
        <br />
        <br />
        <br />
        <div>
          <h1 id="h1">Technology Transition</h1>
          <p className="about-p">
            Join the transition of technology with Xplorer. An ecosystem built
            to support a rapid technology phase. This transition involves three
            distinct phases
          </p>
          <div className="insight">
            <div className="contain">
              <div className="grid">
                <img className="tech-images" src={using} alt="" />
                <h2 id="h2"> Using Technology</h2>
                <p>
                  The Xplorer ecosystem engages people with new refined and
                  eco-friendly technologies, allowing easy adoption of effective
                  technologies worldwide. Serving as a large global market for
                  both investors and technologists.
                </p>
              </div>
              <div className="grid">
                <img className="tech-images" src={integrating} alt="" />
                <h2 id="h2">Integrating Technology</h2>
                <p>
                  Beyond using technologies. Xplorer allows people, generally
                  holders of the Xplorer token, to actively get involved in
                  building future technologies through voting in our techpad
                  space. This phase in our ecosystem brings people's ideas to
                  technologists.
                </p>
              </div>
              <div className="grid">
                <img className="tech-images" src={immerse} alt="" />
                <h2 id="h2">Immersing Technology</h2>
                <p>
                  The final stage of our Technology transition involves getting
                  people fully immersed in technology. To bring people to
                  technology not as technology but as an important extension of
                  human existence and embrace new technological ideas that are
                  energy-efficient and have a positive impact on the
                  environment.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Tech;
