import React from "react";
import { NavLink } from "react-router-dom";
import { community, team, difference, connect, flow } from "../../images";
import { Helmet } from "react-helmet";

const Community = () => {
  return (
    <div>
      <Helmet>
        <title>Xplorer community - Connect and Build</title>
        <meta
          name="description"
          content="Join our community of passionate developers and forward thinking investors"
        />
        <meta
          name="keywords"
          content="Xplorer Token, XPT, cryptocurrency, blockchain, decentralized finance, technology"
        />
        {/* Add more meta tags as needed */}
      </Helmet>
      <div id="home-di">
        <div className="about">
          <img className="img" src={community} alt="" />
          <h2 id="h2">Welcome to Xplorer Community</h2>
          <p className="about-p">
            Join our vibrant and collaborative community at Xplorer. We believe
            in the power of shared knowledge, collective innovation, and
            meaningful connections. Here, you can engage with fellow
            entrepreneurs, investors, and enthusiasts to exchange ideas, seek
            advice, and foster valuable partnerships.
          </p>
        </div>
        <br />
        <br />
        <div className="insight">
          <div className="contain">
            <div className="grid">
              <img className="img" src={difference} alt="Make a Difference" />
              <h2 id="h2">Make a Difference</h2>
              <p>
                Dive into Xplorer, be a force for good! Actively impact lives,
                join, make your mark, and let's shape a better future together.
              </p>
            </div>

            <div className="grid">
              <img className="img" src={team} alt="We are a Team" />
              <h2 id="h2">We are a Team</h2>
              <p>
                Unleash collaboration in Xplorer! Success is a team effort.
                Build through Xplorer, where shared passion accelerates
                progress. Inspire cooperation and achieve greatness together!
              </p>
            </div>

            <div className="grid">
              <img className="img" src={flow} alt="Income Flow" />
              <h2 id="h2">Income flow</h2>
              <p>
                Feel the thrill of continuous income with Xplorer! Through our
                redistribution mechanism, hodlers participate in wealth flow.
                Hold XPT, grow portfolios, contribute to the community, and earn
                from anywhere in the world.
              </p>
            </div>
          </div>
        </div>
        <br />
        <br />

        <div className="about">
          <img className="img" src={connect} alt="" />
          <h2 id="h2">Connect 2.0</h2>
          <p className="about-p">
            There is always someone who is interested in technology or NFT art,
            someone who thinks the way you do. Xplorer allows you to Find and
            connect with like minded individuals to discuss and help solve
            problems.{" "}
          </p>
        </div>
        <br />
        <br />

        <div>
          <div>
            <h2 id="h2">How can I get involved?</h2>
            <p className="about-p">
              Whether you are a seasoned entrepreneur, an aspiring startup
              founder, or an investor looking for the next big opportunity, the
              Xplorer community is your platform to connect, learn, and grow.
              There are many ways to get involved in the fast-growing Xplorer
              community;
            </p>
            <ul id="projects-grid">
              <li className="project-divs">
                <strong>Forums and Discussions</strong> Participate in forums
                and discussions covering a wide range of topics related to
                startups, technology, and innovation. Share your experiences,
                ask questions, and learn from the diverse perspectives of our
                community members.
              </li>

              <li className="project-divs">
                <strong>Events and Meetups</strong> Stay informed about upcoming
                events, webinars, and meetups within the Xplorer community.
                Connect with like-minded individuals, industry experts, and
                potential collaborators during these engaging gatherings.
              </li>

              <li className="project-divs">
                <strong>Collaborative Projects</strong> Explore opportunities
                for collaborative projects with fellow community members.
                Xplorer is a hub for fostering partnerships that can accelerate
                the growth and success of your ventures.
              </li>

              <li className="project-divs">
                <strong>Knowledge Sharing</strong> Contribute to the collective
                knowledge of the community by sharing insights, best practices,
                and success stories. Help others on their entrepreneurial
                journey and gain valuable insights from experienced
                professionals.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div></div>

      <br />
      <br />
      <br />
    </div>
  );
};

export default Community;
