import React from 'react';
import Home from '../components/Home';
import TeamContainer from './TeamContainer';

const HomeContainer = () => {

    return (


        <div className="main-container">
            <div className='one-second-animation' >

                <Home />
            </div>
        </div>

    );

}

export default HomeContainer;