import React, { useState, useEffect } from "react";
import { HashRouter, Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./App.css";
import { logo } from "./images";
import MFATContainer from "./containers/MFATContainer";
import HomeContainer from "./containers/HomeContainer";
import Roadmap from "./containers/RoadmapContainer";
import Footer from "./components/Footer";
import Navbarmenu from "./components/Navbarmenu";
import Discliamer from "./components/Discliamer";
import Faq from "./components/Faq";
import Home from "./components/Home";
import Spinner from "./components/Spinner";
import ScrollToTop from "./components/ScrollToTop";
import ScrollButton from "./components/ScrollButton";
import Technology from "./components/Technology/Technology";
import GetInvolve from "./components/GetInvolve";
import CommunityContainer from "./components/Community/CommunityContainer";
import Defi from "./components/Defi/Defi";
import Blog from "./components/Blog";
import FutureVision from "./components/FutureVision";
import RoadmapDetails from "./components/RoadmapDetails";

const App = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  }, []);

  return (
    <HashRouter>
      <div id="homepage">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Xplorer: Innovation for everyone</title>
          <link rel="icon" href={logo} />
          <meta
            name="description"
            content="Xplorer: Bridging crypto and impact. Discover a world where cryptocurrency fuels meaningful innovation. Ready to make a positive difference? Explore with us!"
          />
          <meta
            name="keywords"
            content="Xplorer Token, XPT, cryptocurrency, blockchain, decentralized finance, technology"
          />

          {/* Open Graph Meta Tags */}
          <meta
            property="og:title"
            content="Xplorer: Bridging crypto and impact, beyond Defi"
          />
          <meta
            property="og:description"
            content="Discover a world where cryptocurrency fuels meaningful innovation. Ready to make a positive difference? Explore with us!"
          />
          <meta property="og:image" content={logo} />
          <meta property="og:url" content="https://xplorertoken.com" />
          <meta property="og:type" content="website" />

          {/* Twitter Card Meta Tags */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content="Xplorer: Bridging crypto and impact"
          />
          <meta
            name="twitter:description"
            content="Discover a world where cryptocurrency fuels meaningful innovation. Ready to make a positive difference? Explore with us!"
          />
          <meta name="twitter:image" content={logo} />
        </Helmet>
        <ScrollToTop />
        {loading ? (
          <Spinner loading={loading} />
        ) : (
          <div>
            <Navbarmenu />
            <br />

            <Routes>
              <Route exact path="/" element={<HomeContainer />} />
              <Route path="/xplorer-token" element={<MFATContainer />} />
              <Route path="/DeFi" element={<Defi />} />
              <Route path="/Blog" element={<Blog />} />
              <Route path="/Community" element={<CommunityContainer />} />
              <Route path="/Faq" element={<Faq />} />
              <Route path="/Roadmap" element={<RoadmapDetails />} />
              <Route path="/Home" element={<Home />} />
              <Route path="/Overview" element={<FutureVision />} />
              <Route path="/Technology" element={<Technology />} />
            </Routes>
            <GetInvolve />
            <Footer />
          </div>
        )}
      </div>
    </HashRouter>
  );
};

export default App;
