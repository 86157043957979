import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { token3D } from "../images";
import { Helmet } from "react-helmet";

const XPT = () => {
  useEffect(() => {
    // Fetch token details or set them based on props
    const tokenName = "Xplorer Token";
    const tokenSymbol = "XPT";

    // Update meta tags dynamically
    document.title = `${tokenName} - ${tokenSymbol} Details`;
  }, []);
  const myFunction = () => {
    /* Get the text field */
    var copyText = document.getElementById("myInput");

    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    navigator.clipboard.writeText(copyText.value);

    /* Alert the copied text */
    alert("Address Copied: " + copyText.value);
  };
  return (
    <div>
      <Helmet>
        <title>Xplorer Token - XPT</title>
        <meta name="description" content="Details of Xplorer Token (XPT)" />
        <meta
          name="keywords"
          content="Xplorer Token, XPT, cryptocurrency, blockchain, decentralized finance, technology"
        />
        {/* Add more meta tags as needed */}
      </Helmet>
      <div style={{ background: "black" }} id="home">
        <div id="home-div">
          <br />
          <img className="token3D star" src={token3D} alt="" />
          <div className="Objectives individual-resume-divs">
            <h2 id="h2">Xplorer Token</h2>
            <p className="about-p">
              Xplorer Token (XPT) is the cornerstone of our platform, designed
              to provide a secure and versatile utility for users. It acts as
              more than just a cryptocurrency; it's your key to unlocking
              opportunities and securing meaningful partnerships within the
              Xplorer ecosystem.
            </p>
            <a href="https://dapp.xplorertoken.space/#/Stake">
              <button className="learn-more-btn">Stake XPT</button>
            </a>
          </div>
        </div>
        <div className="home-div">
          <div>
            <div>
              <div id="projects-grid">
                <div className="project-div">
                  <strong>Transaction Power</strong>
                  <p>
                    XPT serves as the primary currency for transactions within
                    the Xplorer ecosystem, ensuring swift and secure exchanges
                    for users.
                  </p>
                </div>

                <div className="project-div">
                  <strong>Premium Features</strong>
                  <p>
                    Holders of XPT gain access to premium features and exclusive
                    services within the platform, enhancing their overall
                    experience and benefits.
                  </p>
                </div>
                <div className="project-div">
                  <strong>Community Engagement</strong>
                  <p>
                    XPT is more than a token; it's a bridge to community
                    engagement. Holders can actively participate in governance,
                    decision-making processes, and community-driven initiatives,
                    shaping the future of Xplorer.
                  </p>
                </div>
                <div className="project-div">
                  <strong>Rewards and Incentives</strong>
                  <p>
                    Active participation and holding XPT may lead to rewards,
                    incentives, and exclusive opportunities. XPT holders are
                    valued contributors to our vibrant and participatory
                    community.
                  </p>
                </div>
                <div className="project-div">
                  <strong>Secure Partnerships</strong>
                  <p>
                    XPT acts as a secure gateway to meaningful partnerships
                    within the Xplorer ecosystem. Holding XPT demonstrates
                    commitment and investment in the growth and success of your
                    venture.
                  </p>
                </div>
                <div className="project-div">
                  <strong>Flexible Liquidity</strong>
                  <p>
                    XPT can be sold anytime, providing users with flexibility
                    and liquidity. This feature ensures that XPT holders have
                    control over their assets and can make strategic decisions
                    based on market conditions.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />

          <div className="Objectives individual-resume-divs">
            <h2 className="token"> How to Acquire XPT</h2>

            <p>
              XPT can be acquired through various means, including participation
              in token sales, ICOs, and engagement in the Xplorer platform. As a
              key element of the Xplorer ecosystem, XPT is distributed in a fair
              and transparent manner to contributors and stakeholders.
            </p>
            <br />
            <br />

            <h3 className="token">Join the XPT Economy</h3>

            <p>
              Becoming part of the XPT economy means not only accessing a
              powerful utility token but also contributing to the growth and
              development of the Xplorer community. Hold, trade, and utilize XPT
              to unlock the full potential of your Xplorer experience.
            </p>
            <br />
            <br />

            <h3 className="token">Risks and Considerations</h3>

            <p>
              While XPT offers numerous benefits, users should be aware of
              associated risks. Market volatility, regulatory changes, and other
              factors may impact the value and utility of XPT. It's essential to
              stay informed and make informed decisions when engaging with XPT.
            </p>
            {/*
            <input
              type="text"
              value="0xDfd16D4863932F1A3Ac247a07f3Fe7E1FF56a4f1"
              id="myInput"
            />
            <button className="button" onClick={myFunction}>
              Copy Address
            </button>
            <span>
              {" "}
              <a
                href="https://bscscan.com/address/0xDfd16D4863932F1A3Ac247a07f3Fe7E1FF56a4f1"
                target="_blank"
              >
                <button className="button">View on Bscscan</button>
              </a>
            </span>
            */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default XPT;
