import React from "react";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <div>
      <div className="footer">
        <div className="credit">Follow us</div>
        <div className="col-md-3">
          <div id="footer-socials">
            <div className="socials inline-inside socials-colored">
              <a
                href="http://twitter.com/OxXplorer"
                target="_blank"
                title="Twitter"
                className="socials-item"
              >
                <i className="fab fa-twitter twitter"></i>
              </a>
              <a
                href="http://t.me/xplorerToken"
                target="_blank"
                title="Telegram"
                className="socials-item"
              >
                <i className="fab fa-telegram telegram"></i>
              </a>
              <a
                href="https://www.discord.gg/HwUPAHQFNM"
                target="_blank"
                title="discord"
                className="socials-item"
              >
                <i className="fab fa-discord discord"></i>
              </a>
              <a
                href="https://www.reddit.com/u/Xplorertoken"
                target="_blank"
                title="reddit"
                className="socials-item"
              >
                <i className="fab fa-reddit reddit"></i>
              </a>

              <a
                href="http://www.github.com/Xplorertoken"
                target="_blank"
                title="github"
                className="socials-item"
              >
                <i className="fab fa-github github"></i>
              </a>
              <a
                href="http://www.instagram.com/xplorerToken"
                target="_blank"
                title="instagram"
                className="socials-item"
              >
                <i className="fab fa-instagram instagram"></i>
              </a>
            </div>

            <br />
            <br />

            <div className="credit">
              All right reserved @Xplorer Token, 2024
            </div>
            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
