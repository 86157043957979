import React from "react";
import { Link } from "react-router-dom";

const Utilities = (props) => {
  const handleGetName = (UtilityName) => {
    handleChangeCurrentlyShowing(UtilityName);
  };

  const handleChangeCurrentlyShowing = (UtilityName) => {
    props.handleChangeCurrentlyShowing(UtilityName);
  };

  return (
    <div
      className="utility-link-container"
      id="projects-grid"
      style={{ flexWrap: "nowrap" }}
    >
      <Link to="/Overview" className=" utility-link">
        <h2 className=" glitch text project-div-headers">Social Impact</h2>
      </Link>

      <Link to="/Overview" className=" utility-link">
        <h2 className=" glitch text project-div-headers  ">
          Environmental Sustainability
        </h2>
      </Link>

      <Link to="/Overview" className="utility-link">
        {" "}
        <h2 className=" glitch text project-div-headers">
          Technological Innovation
        </h2>
      </Link>

      <Link to="/Overview" className=" utility-link">
        <h2 className=" glitch text project-div-headers">
          Community Engagement
        </h2>
      </Link>

      <Link to="/Overview" className=" utility-link">
        <h2 className=" glitch text project-div-headers">Global Impact</h2>
      </Link>

      <Link to="/Overview" className="utility-link">
        <h2 className=" glitch text project-div-headers">Future Vision</h2>
      </Link>
    </div>
  );
};

export default Utilities;
